import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../../shared/Header';
import { API_URL } from '../../../global/constants';
import axios from 'axios';
import {
	Grid,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useMediaQuery,
	Stack,
	Box,
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ProfileIcon from './images/porfile-icon.png';
import EmailIcon from './images/icons8-mail-48.png';
import CollegeIcon from './images/colleg-icon.png';
import PhoneIcon from './images/phone-icon.png';
import CompleteIcon from './images/complete-icon.png';
import PercentageIcon from './images/percentage-icon.png';
import BestIcon from './images/best-icon.png';
import BadgeIcon from './images/star-medal.png';
import AvgTimeIcon from './images/avg-time-icon.png';
import TimeIcon from './images/time-icon.png';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const DemoPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	...theme.typography.body2,
	textAlign: 'center',
	borderRadius: '15px',
	width: '90vw',
	maxWidth: '500px',
}));

ChartJS.register(ArcElement, Tooltip, Legend);

const StudentProfile = () => {
	const [totalInterviews, setTotalInterviews] = useState(0);
	const [interviewsGiven, setInterviewsGiven] = useState(0);
	const [incompleteInterviews, setIncompleteInterviews] = useState(0);
	const [pendingInterviews, setPendingInterviews] = useState(0);

	const data = {
		labels: ['Completed', 'To be started', 'Incomplete'],
		datasets: [
			{
				label: 'Mock interviews',
				data: [interviewsGiven, pendingInterviews, incompleteInterviews],
				backgroundColor: ['green', 'rgb(54, 162, 235)', 'red'],
				hoverOffset: 4,
			},
		],
	};

	const config = {
		type: 'doughnut',
		data: data,
		options: {
			plugins: {
				tooltip: {
					callbacks: {
						label: function (context) {
							const label = context.dataset.label || '';
							const dataLabel = context.label || '';
							const value = context.raw || 0;
							return `${dataLabel}: ${value}`;
						},
					},
				},
			},
		},
	};

	const isMobile = useMediaQuery('(max-width:600px)');

	const StatCard = styled(Paper)(({ theme }) => ({
		padding: theme.spacing(2),
		textAlign: 'center',
		borderRadius: '12px',
		minWidth: '120px',
		width: isMobile ? '100%' : '100%',
		maxWidth: '200px',
		flexGrow: 1,
		// margin: isMobile ? '' : '0.4vw',
		flexBasis: isMobile ? 'calc(50% - 2vw)' : 'calc(25% - 2vw)',
		flexGrow: 1,
	}));

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const [college, setCollege] = useState('');
	const [profileImage, setProfileImage] = useState('');
	const fullName = `${firstName} ${lastName}`;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// Mock interview stats
	const [totalCompletedInterviews, setTotalCompletedInterviews] = useState(0);
	const [avgRating, setAvgRating] = useState(0);
	const [bestPerformance, setBestPerformance] = useState('');
	const [bestPerformanceScore, setBestPerformanceScore] = useState();
	const [timeSpent, setTimeSpent] = useState({ hours: 0, minutes: 0 });
	const [averageTimeSpent, setAverageTimeSpent] = useState({ hours: 0, minutes: 0 });
	const [interviewData, setInterviewData] = useState([]);

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/details`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			setFirstName(data.studentDetails.first_name);
			setLastName(data.studentDetails.last_name);
			setEmail(data.studentDetails.email);
			setContact(data.studentDetails.contact);
			setCollege(data.studentDetails.college);
			setProfileImage(data.studentDetails.profile_image);
		} catch (e) {
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	const getStudentStats = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/student/stats`, {
				headers: {
					authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
				},
			});
			setTotalInterviews(data.totalMockInterviews);
			setInterviewsGiven(data.givenMockInterviewsCount);
			setPendingInterviews(data.pendingInterviews);
			setAvgRating(Math.round(data.averageScore));
			setTotalCompletedInterviews(data.completedCount);
			setBestPerformanceScore(data.highestScoreInterview.overall_score);
			setBestPerformance(data.highestScoreInterview.interview_name);
			setTimeSpent(data.totalTimeSpent || { hours: 0, minutes: 0 });
			setAverageTimeSpent(data.averageTimeSpent || { hours: 0, minutes: 0 });
			setIncompleteInterviews(data.incompleteMockInterview);
			const processedInterviewData = data.attempts.map((attempt) => ({
				name: attempt.interviewName,
				attempts: attempt?.attemptsCount,
				avgRating: attempt?.averageJobScore || '0',
				bestRating: attempt?.bestScore || '0',
			}));
			setInterviewData(processedInterviewData);
		} catch (e) {
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!sessionStorage.getItem('student-auth-token')) {
			navigate('/student/login');
		}
		getDetails();
		getStudentStats();
	}, []);

	const formatTimeSpent = (time) => {
		if (time.hours > 0) {
			return `${time.hours} hrs`;
		} else {
			return `${time.minutes} mins`;
		}
	};

	return (
		<>
			<Toaster />
			<Header fullName={fullName} />
			<Stack
				p={{ sm: 5 }}
				spacing={{ sm: 5, xs: 3 }}
				style={{
					backgroundColor: '#ecf7fa',
					maxWidth: '100vw',
					padding: '5vw',
					margin: isMobile ? '8vw 2vw 0 2vw' : '',
					overflowX: 'hidden',
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: isMobile ? 'column' : 'row',
						gap: '5vw',
						width: '100%',
					}}
				>
					<DemoPaper square={false} style={{ maxWidth: isMobile ? '90vw' : '50vw' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: isMobile ? 'column' : 'row',
								alignItems: 'center',
								padding: isMobile ? '3vw' : '5vw',
								gap: '5vw',
							}}
						>
							<img
								style={{ borderRadius: '50%', width: isMobile ? '25vw' : '150px' }}
								src={profileImage}
								alt="profile"
							/>
							<div
								style={{
									marginLeft: isMobile ? '0' : '2vw',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'start',
									textAlign: 'start',
								}}
							>
								<h1> {fullName}</h1>
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										gap: '2vw',
									}}
								>
									<img
										style={{
											width: isMobile ? '8vw' : '30px',
											height: isMobile ? '8vw' : '30px',
										}}
										src={EmailIcon}
										alt="email"
									/>
									<h3>{email}</h3>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										gap: '2vw',
									}}
								>
									<img
										style={{
											width: isMobile ? '8vw' : '30px',
											height: isMobile ? '8vw' : '30px',
										}}
										src={CollegeIcon}
										alt="college"
									/>
									<h3>{college}</h3>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										gap: '2vw',
									}}
								>
									<img
										style={{
											width: isMobile ? '8vw' : '30px',
											height: isMobile ? '8vw' : '30px',
										}}
										src={PhoneIcon}
										alt="phone"
									/>
									<h3>{contact}</h3>
								</div>
							</div>
						</div>
					</DemoPaper>
					<DemoPaper
						square={false}
						style={{
							width: isMobile ? '90vw' : '45vw',
							height: isMobile ? 'auto' : '417px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: isMobile ? 'center' : 'space-between',
								alignItems: 'center',
								textAlign: 'center',
								padding: '4vw',
								gap: '1.1vw',
								height: isMobile ? '50vh' : '100%',
							}}
						>
							<Typography variant="h6">Unlock your first badge</Typography>
							<img
								style={{
									width: isMobile ? '45vw' : '12vw',
									filter: 'grayscale(100%)',
								}}
								src={BadgeIcon}
								alt="medal icon"
							/>
							<Typography
								variant="h6"
								style={{ filter: 'grayscale(100%)', color: 'gray' }}
							>
								Fullstack Developer
							</Typography>
							<Typography variant="body1">Score 90% to unlock the badge</Typography>
						</div>
					</DemoPaper>
				</div>

				{/* Interview Stats Section */}
				<div
					className="interview-stats"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'start',
						textAlign: 'start',
						alignItems: 'start',
						marginTop: isMobile ? '14vw' : '5rem',
						maxWidth: isMobile ? '100vw' : '100vw',
					}}
				>
					<Typography variant="h4" gutterBottom>
						Interview stats
					</Typography>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
							placeContent: 'center',
							justifyContent: 'center',
							width: '100%',
							gap: '16px',
							marginTop: '2vw',
							'@media (max-width: 600px)': {
								gridTemplateColumns: 'repeat(2, 1fr)', // For mobile screens
							},
							'@media (min-width: 600px) and (max-width: 960px)': {
								gridTemplateColumns: 'repeat(3, 1fr)', // For tablet screens
							},
							'@media (min-width: 960px)': {
								gridTemplateColumns: 'repeat(4, 1fr)', // For laptop and larger screens
							},
						}}
					>
						<StatCard
							elevation={3}
							sx={{
								height: '100%',
								padding: '16px',
								'@media (max-width: 600px)': {
									padding: '8px', // Smaller padding for mobile screens
									'& .MuiTypography-h4': {
										fontSize: '1.5rem', // Smaller font size for mobile screens
									},
									'& .MuiTypography-h6': {
										fontSize: '1rem', // Smaller font size for mobile screens
									},
									'& img': {
										width: '50px', // Smaller image size for mobile screens
									},
								},
							}}
						>
							<Typography variant="h4">
								<img src={CompleteIcon} alt="completeicon" />
							</Typography>
							<Typography variant="h4">{totalCompletedInterviews}</Typography>
							<Typography variant="h6">Interviews completed</Typography>
						</StatCard>

						<StatCard
							elevation={3}
							sx={{
								height: '100%',
								padding: '16px',
								'@media (max-width: 600px)': {
									padding: '8px', // Smaller padding for mobile screens
									'& .MuiTypography-h4': {
										fontSize: '1.5rem', // Smaller font size for mobile screens
									},
									'& .MuiTypography-h6': {
										fontSize: '1rem', // Smaller font size for mobile screens
									},
									'& img': {
										width: '50px', // Smaller image size for mobile screens
									},
								},
							}}
						>
							<Typography variant="h4">
								<img src={PercentageIcon} alt="ratingicon" />
							</Typography>
							<Typography variant="h4">{avgRating}%</Typography>
							<Typography variant="h6">Average percentage</Typography>
						</StatCard>

						<StatCard
							elevation={3}
							sx={{
								height: '100%',
								padding: '16px',
								'@media (max-width: 600px)': {
									padding: '8px', // Smaller padding for mobile screens
									'& .MuiTypography-h4': {
										fontSize: '1.5rem', // Smaller font size for mobile screens
									},
									'& .MuiTypography-h6': {
										fontSize: '1rem', // Smaller font size for mobile screens
									},
									'& img': {
										width: '50px', // Smaller image size for mobile screens
									},
								},
							}}
						>
							<Typography variant="h4">
								<img src={TimeIcon} alt="timeicon" />
							</Typography>
							<Typography variant="h4">{formatTimeSpent(timeSpent)}</Typography>
							<Typography variant="h6">Total time spent</Typography>
						</StatCard>

						<StatCard
							elevation={3}
							sx={{
								height: '100%',
								padding: '16px',
								'@media (max-width: 600px)': {
									padding: '8px', // Smaller padding for mobile screens
									'& .MuiTypography-h4': {
										fontSize: '1.5rem', // Smaller font size for mobile screens
									},
									'& .MuiTypography-h6': {
										fontSize: '1rem', // Smaller font size for mobile screens
									},
									'& img': {
										width: '50px', // Smaller image size for mobile screens
									},
								},
							}}
						>
							<Typography variant="h4">
								<img src={AvgTimeIcon} alt="timeicon" />
							</Typography>
							<Typography variant="h4">
								{formatTimeSpent(averageTimeSpent)}
							</Typography>
							<Typography variant="h6">Avg time spent per interview</Typography>
						</StatCard>
					</div>
					<Grid
						container
						spacing={3}
						style={{
							maxWidth: isMobile ? '90vw' : '',
							textAlign: 'center',
							marginTop: '5vw',
							marginBottom: '4vw',
						}}
					>
						<Grid item>
							<DemoPaper
								elevation={3}
								style={{
									maxWidth: isMobile ? '86vw' : '',
									height: '100%',
									padding: '4vw',
								}}
							>
								<Typography variant="h5">Interviews</Typography>
								{data && data.datasets && data.datasets.length > 0 ? (
									<Doughnut data={data} options={config} />
								) : (
									<Typography variant="body1">No data available</Typography>
								)}
							</DemoPaper>
						</Grid>

						<Grid item style={{ width: isMobile ? '100vw' : '52vw' }}>
							{isMobile ? (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: '16px',
										padding: '16px 0',
										width: '100%',
									}}
								>
									<Typography variant="h5">Interview ratings</Typography>
									{interviewData.length > 0 ? (
										interviewData.map((interview, index) => (
											<Box
												key={index}
												sx={{
													border: '1px solid #e0e0e0',
													borderRadius: '8px',
													padding: '16px',
													textAlign: 'left',
													backgroundColor: '#ffffff',
													boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
													width: '100%',
												}}
											>
												<Typography variant="h6" fontWeight="bold">
													{interview.name}
												</Typography>
												<Typography
													variant="body2"
													color="textSecondary"
													sx={{ mt: 1 }}
												>
													No. of attempts: {interview.attempts}
												</Typography>
												<Typography variant="body2" color="textSecondary">
													Average percentage: {interview.avgRating}%
												</Typography>
												<Typography variant="body2" color="textSecondary">
													Best percentage: {interview.bestRating}%
												</Typography>
											</Box>
										))
									) : (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: '16px',
												padding: '16px 0',
												width: '100%',
											}}
										>
											No data available
										</Box>
									)}
								</Box>
							) : (
								<DemoPaper
									style={{ height: '100%', maxWidth: isMobile ? '86vw' : '50vw' }}
								>
									<Typography variant="h5">Interview ratings</Typography>
									<TableContainer
										component={Paper}
										style={{
											marginTop: isMobile ? '12px' : '2px',
											overflowY: 'auto',
											maxHeight: '460px',
										}}
									>
										<Table aria-label="interview rating table">
											<TableHead>
												<TableRow>
													<TableCell sx={{ fontWeight: 'bold' }}>
														Interview name
													</TableCell>
													<TableCell
														align="center"
														sx={{ fontWeight: 'bold' }}
													>
														No. of attempts
													</TableCell>
													<TableCell
														align="center"
														sx={{ fontWeight: 'bold' }}
													>
														Average percentage
													</TableCell>
													<TableCell
														align="center"
														sx={{ fontWeight: 'bold' }}
													>
														Best percentage
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{interviewData.length > 0 ? (
													interviewData.map((row) => (
														<TableRow key={row.id}>
															<TableCell component="th" scope="row">
																{row.name}
															</TableCell>
															<TableCell align="center">
																{row.attempts}
															</TableCell>
															<TableCell align="center">
																{row.avgRating}%
															</TableCell>
															<TableCell align="center">
																{row.bestRating}%
															</TableCell>
														</TableRow>
													))
												) : (
													<TableRow>
														<TableCell colSpan={4} align="center">
															No data available
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</DemoPaper>
							)}
						</Grid>
					</Grid>
				</div>
			</Stack>
		</>
	);
};

export default StudentProfile;
